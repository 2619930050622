import React from 'react';
import { Layout } from '../../components/common';
import SEO from '../../components/common/SEO';
import { useViewport } from '../../hooks';
import DesktopEventPage from '../../components/common/DesktopEventPage';
import MobileEventPage from '../../components/common/MobileEventPage';

import banner from '../../images/events/2025/plugfest/plugfest-event-banner-desktop.jpg';
import mobileBanner from '../../images/events/2025/plugfest/plugfest-event-banner-mobile.jpg';
import ogimage from '../../images/events/2025/plugfest/plugfest-event-og.jpg';

export default function Plugfest2025() {
    const { width } = useViewport();
    const breakpoint = 500;

    return (
        <Layout title='Plugfest 2025' isEvents>
            <SEO
                title='Plugfest 2025'
                ogDescription='Witbe recently joined DTG and is excited to participate in the Plugfest!'
                description='Witbe recently joined DTG and is excited to participate in the Plugfest!'
                image={ogimage}
            />

            {width < breakpoint ? <MobileEventPage id={20} banner={mobileBanner} /> : <DesktopEventPage id={20} banner={banner} />}
        </Layout>
    )
}
